import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2011 Mensajes" />
      <h1>2011 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2011, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.{' '}
      </p>
      <h2>2011 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="74">02/01/11</td>
            <td width="126">Jason Henderson </td>
            <td colSpan="2">La Santificación 4 </td>
            <td width="44">
              <div align="center">
                <a href={asset('mensajes/11/mp3/01_Santificacion_04.mp3')}>audio</a>
              </div>
            </td>
            <td width="41">
              <div align="center">
                <a href={asset('mensajes/11/pdf/101205Santificacion04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>09/01/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 1 - (Sacerdocio 1) </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/02_Reino_de_Sacerdotes_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110109ReinoDeSacerdotes01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>30/01/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 2 - (Sacerdocio 2) </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/03_Reino_de_Sacerdotes_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110130ReinoDeSacerdotes02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/02/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 3 - (Sacerdocio 3) </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/04_Reino_de_Sacerdotes_03.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110206ReinoDeSacerdotes03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>06/03/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 4 - (Sacerdocio 4) </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/05_Reino_de_Sacerdotes_04.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110227ReinoDeSacerdotes04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/03/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 5 - (Reino 1)</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/06_Reino_de_Sacerdotes_05.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110320ReinoDeSacerdotes05.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>27/03/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 6 - (Reino 2)</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/07_Reino_de_Sacerdotes_06.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110327ReinoDeSacerdotes06.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>17/04/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 7 - (Reino 3)</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/08_Reino_de_Sacerdotes_07.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110417ReinoDeSacerdotes07.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>08/05/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 8 - (Reino 4)</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/09_Reino_de_Sacerdotes_08.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110508ReinoDeSacerdotes08.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/05/11</td>
            <td>Grace Montero </td>
            <td colSpan="2">No En Las de La Tierra</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110522NoEnLasDeLaTierra.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>29/05/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Reino de Sacerdotes 9 - (Reino 5) </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/10_Reino_de_Sacerdotes_09.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110529ReinoDeSacerdotes09.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>05/06/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">El Día del Se&ntilde;or 1 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/11_El_Dia_del_Senor_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110605ElDia01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>12/06/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">El Día del Se&ntilde;or 2 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/12_El_Dia_del_Senor_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110612ElDia02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>19/06/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">El Día del Se&ntilde;or 3 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/13_El_Dia_del_Senor_03.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110619ElDia03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>24/07/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">¿Qué Es Epiritual?</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/14_Que_Es_Espiritual.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110724QueEsEspiritual.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>31/07/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">Continuar En La Fe</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/15_Continuar_En_La_Fe.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110731ContinuarEnFe.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>07/08/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">La Verdadera Comunión 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/16_La_Verdadera_Comunion_01.mp3')}>
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110807Comunion.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/08/11</td>
            <td>Jason Henderson </td>
            <td colSpan="2">La Verdadera Comunión 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/17_La_Verdadera_Comunion_02.mp3')}>
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110814Comunion02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>28/08/11</td>
            <td>Grace Montero</td>
            <td colSpan="2">Recomendaciones Para Continuar en Nuestro Viaje de Fe</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110828Recomendaciones.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/09/11</td>
            <td>Jason Henderson</td>
            <td colSpan="2">Buenas Obras 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/18_Buenas_Obras_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/110904BuenasObras01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>16/10/11</td>
            <td>Jason Henderson</td>
            <td colSpan="2">Buenas Obras 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/19_Buenas_Obras_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/pdf/111002BuenasObras02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>06/10/11</td>
            <td>Jason Henderson</td>
            <td width="284">Gracia a Vosotros pt. 1 </td>
            <td width="85">
              <a
                href={asset('mensajes/Efesios/Diagrams/Ley_Y_Gracia.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              >
                diagrama
              </a>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/20_Gracia_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/01Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/11/11</td>
            <td>Jason Henderson</td>
            <td>Gracia a Vosotros pt. 2 </td>
            <td>
              <a
                href={asset('mensajes/Efesios/Diagrams/Ley_y_Gracia_2.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              >
                diagrama
              </a>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/21_Gracia_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/02Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>27/11/11</td>
            <td>Jason Henderson</td>
            <td colSpan="2">Cristo Nuestra Paz </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/22_Paz.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/03Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>04/12/11</td>
            <td>Jason Henderson</td>
            <td colSpan="2">Bendiciones Espirituales en Cristo</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/11/mp3/23_Bendicion.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/04Efesios_1_3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
